import React, {useCallback, useEffect, useState} from 'react'
import DialogSlide from './Dialog'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Button, Spinner} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {positionDragItem} from 'store/actions/menuActions';
import {Switch} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import FormControlLabel from '@mui/material/FormControlLabel';


export default function ReorderDialog({isConfigModalOpen, handleCloseConfigModal,}) {
    const {
        getMenuLoader,
        menus,
        loading: menuDragLoading
    } = useSelector((state) => state.menu);

    // let [activeMenus, setActiveMenus] = useState([]);
    const [groups, setGroups] = useState([]);
    const {blId} = useSelector((state) => state.business);
    const [isDragConfirmationOpen, setIsDragConfirmationOpen] = useState(false); // Track drag confirmation
    const [draggedSection, setDraggedSection] = useState(null);

    const handleConfirmDrag = () => {
        if (draggedSection) {
            dispatch(positionDragItem(draggedSection, blId, menus));
            setIsDragConfirmationOpen(false);
        }
    };

    const handleCancelDrag = () => {
        setIsDragConfirmationOpen(false);
    };

    const dispatch = useDispatch();

    const groupData = (dataList) => {
        let obj = {};
        dataList?.forEach((data) => {
            if (!obj[data.group]) {
                let temp = {
                    group: data.group,
                    id: data.id,
                    position: data.position,
                    items: []
                };
                obj[data.group] = temp;
            }
            obj[data.group].items.push(data);
        });

        return sortByPosition(obj);
    };

    const sortByPosition = (obj) => {
        return Object.values(obj).sort((a, b) => {
            if (!b.position) {
                return -1;
            }
            return a.position - b.position;
        });
    };

    const groupMenus = () => {
        if (!menus.length) return [];
        const menuGroup = groupData(menus);
        return menuGroup;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const temp = groupMenus();
        let tag = temp[0]?.items[0]?.menu_tags;
        if (temp[result.source.index].id === temp[result.destination.index].id) return;
        if (result.source.index === 'undefined' || result.destination.index === 'undefined') return;

        const updatedSection = {
            startIndex: result.source.index,
            endIndex: result.destination.index,
            menu_tags: tag,
        };

        updatedSection.startIndex = result.source.index;
        updatedSection.endIndex = result.destination.index;
        setDraggedSection(updatedSection);
        setIsDragConfirmationOpen(true); // Open the confirmation dialog
    };

    useEffect(() => {
        const data = groupData(menus);
        setGroups(data);
    }, [menus]);

    const [isDraggable, setIsDraggable] = useState(false)
    const toggleDraggable = useCallback(
        (event) => {
            event.target.checked ? setIsDraggable(true) : setIsDraggable(false);
        },
        []
    )


    return (
        //  article configuration dialog
        <DialogSlide
            open={isConfigModalOpen}
            handleClose={handleCloseConfigModal}
            title={'Artikel neu positionieren'}
            // save={handleReorder}
        >
            <div className='flex flex-col gap-2 w-full'>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isDraggable}
                                onChange={toggleDraggable}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        }
                        label="Neuausrichtung"
                        labelPlacement="start"
                    />
                </div>
                {!isDragConfirmationOpen && (<DragDropContext
                        onDragEnd={onDragEnd}
                    >
                        <Droppable droppableId="droppableArticle">
                            {(provided) => {
                                return (
                                    <div ref={provided.innerRef}>
                                        {<>{getMenuLoader || menuDragLoading ? (
                                            <Spinner className="d-flex mx-auto mt-5" size="lg" color="primary"/>
                                        ) : (
                                            <>

                                                {menus ? (
                                                    <>
                                                        {groups.map((data, k) => {
                                                            return (<>
                                                                    <Draggable
                                                                        key={k}
                                                                        draggableId={k.toString()}
                                                                        index={k}
                                                                        isDragDisabled={!isDraggable}
                                                                    >
                                                                        {(provided) => {
                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        className="d-flex align-items-center p-3"
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        {isDraggable &&
                                                                                            <DragHandleIcon/>}

                                                                                        <span
                                                                                            style={{
                                                                                                justifyContent: 'space-between',
                                                                                                display: 'flex',
                                                                                                width: '100%'
                                                                                            }}
                                                                                        >
                                                  <h6 className="ml-3 mt-1"
                                                      style={{
                                                          color: isDraggable ? 'black' : 'gray',
                                                      }}
                                                  >
                                                    {data.group}
                                                  </h6>
                                                  <span
                                                      style={{
                                                          fontStyle: 'italic'
                                                      }}
                                                  >
                                                    {data.position}
                                                  </span>
                                                </span>
                                                                                    </div>
                                                                                </>)
                                                                        }}</Draggable>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <div className="p-5">Empty menu</div>
                                                )}
                                            </>
                                        )}
                                        </>
                                        }
                                    </div>
                                )
                            }
                            }
                        </Droppable>
                    </DragDropContext>
                )}

                {/* Confirmation Dialog for Drag and Drop */}
                <DialogSlide
                    title={'Sind Sie sicher, dass Sie die Änderungen speichern möchten ?'}
                    open={isDragConfirmationOpen}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <div style={{display: 'flex', gap: '1rem'}}>
                            <Button
                                color={'danger'}
                                onClick={handleCancelDrag}
                            >
                                Stornieren
                            </Button>
                            <Button
                                color={'primary'}
                                onClick={handleConfirmDrag}
                            >
                                Bestätigen
                            </Button>
                        </div>
                    </div>
                </DialogSlide>

            </div>
        </DialogSlide>
    )
}
