import { RepositoryFactory } from '../../repository/RepositoryFactory';

import {
  FETCH_SINGLE_MENU_ITEMS_REQUEST,
  FETCH_SINGLE_MENU_ITEMS_SUCCESS,
  FETCH_SINGLE_MENU_ITEMS_FAILURE
} from 'store/Types/MenuTypes';
import { FETCH_MENU_REQUEST, FETCH_MENU_FAILURE, FETCH_MENU_SUCCESS } from 'store/Types/MenuTypes';
import {
  SET_MENU_POSITION_REQUEST,
  SET_MENU_POSITION_SUCCESS,
  SET_MENU_POSITION_FAILURE
} from 'store/Types/MenuTypes';
import {
  FETCH_KIOSK_MENU_REQUEST,
  FETCH_KIOSK_MENU_FAILURE,
  FETCH_KIOSK_MENU_SUCCESS
} from 'store/Types/MenuTypes';
import { UPDATE_MENU_POSITION_SUCCESS } from 'store/Types/MenuTypes';
import { UPDATE_MENU_POSITION_FAILURE } from 'store/Types/MenuTypes';
import { UPDATE_MENU_POSITION_REQUEST } from 'store/Types/MenuTypes';
import {
  FETCH_TAKEAWAY_MENU_REQUEST,
  FETCH_TAKEAWAY_MENU_FAILURE,
  FETCH_TAKEAWAY_MENU_SUCCESS
} from 'store/Types/MenuTypes';
import {
  SET_PRIORITY_REQUEST,
  SET_PRIORITY_FAILURE,
  SET_PRIORITY_SUCCESS
} from 'store/Types/MenuTypes';
import {
  DELETE_MENU_REQUEST,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_FAILURE
} from 'store/Types/MenuTypes';
import {
  CREATE_MENU_REQUEST,
  CREATE_MENU_FAILURE,
  CREATE_MENU_SUCCESS
} from 'store/Types/MenuTypes';
import {
  FETCH_ALLE_ARTIKELENA_REQUEST,
  FETCH_ALLE_ARTIKELENA_FAILURE,
  FETCH_ALLE_ARTIKELENA_SUCCESS
} from 'store/Types/MenuTypes';
import {
  ADD_SINGLE_MENU_REQUEST,
  ADD_SINGLE_MENU_SUCCESS,
  ADD_SINGLE_MENU_FAILURE
} from 'store/Types/MenuTypes';

var menuRepository = RepositoryFactory.get('menu');
export const addMenu =
  (payload, onSuccess = () => { }) =>
    async (dispatch) => {
      dispatch({
        type: ADD_SINGLE_MENU_REQUEST
      });

      try {
        let { data } = await menuRepository.addMenu(payload);
        if (data?.success) {
          // console.log({ data });
          dispatch(getAllMenus(payload?.blId));
          dispatch(getAllTakeAwayMenus(payload?.blId));
          dispatch(getAllKioskMenu(payload?.blId));
          dispatch({
            type: ADD_SINGLE_MENU_SUCCESS,
            payload: data?.data
          });
          onSuccess();
        } else {
          alert('Failed to add single menu');
          dispatch({
            type: ADD_SINGLE_MENU_FAILURE
          });
        }
      } catch (e) {
        alert(e.message);
        dispatch({
          type: ADD_SINGLE_MENU_FAILURE
        });
      }
    };
export const getAllAlleArtikelen = (id, menu_id) => async (dispatch) => {
  // dispatch(getAlleArtikelenLoader(true));
  console.log('getting all artikelen');
  dispatch({
    type: FETCH_ALLE_ARTIKELENA_REQUEST
  });
  try {
    let { data } = await menuRepository.getAllAlleArtikelen(id, menu_id);
    if (data?.success) {
      dispatch({
        type: FETCH_ALLE_ARTIKELENA_SUCCESS,
        payload: data?.data
      });
      // dispatch(getAlleArtikelenLoader(false));
    } else {
      dispatch({
        type: FETCH_ALLE_ARTIKELENA_FAILURE
      });
    }
  } catch (e) {
    alert(e.msg);
    dispatch({
      type: FETCH_ALLE_ARTIKELENA_FAILURE
    });
  }
  // dispatch(getAlleArtikelenLoader(false));
};
export const setMenus = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_MENU_REQUEST
    });
    if (data.success) {
      dispatch({
        type: FETCH_MENU_SUCCESS,
        payload: data.data
      });
      // dispatch(getMenuLoader(false));
    } else {
      dispatch({
        type: FETCH_MENU_FAILURE
      });
      // dispatch(getMenuLoader(false));
    }
  } catch (e) {
    alert(e.message);
    dispatch({
      type: FETCH_MENU_FAILURE
    });
    // dispatch(getMenuLoader(false));
  }
};
export const getAllMenus = (id) => async (dispatch) => {
  // dispatch(getMenuLoader(true));
  dispatch({
    type: FETCH_MENU_REQUEST
  });
  try {
    let { data } = await menuRepository.getAllMenus(id);
    if (data.success) {
      const sort = data?.data?.sort((a, b) => {
        if (!a?.position || !b?.position) {
          return -1;
        }
        return a?.position - b?.position;
      });

      dispatch({
        type: FETCH_MENU_SUCCESS,
        payload: sort
      });
      // dispatch(getMenuLoader(false));
    } else {
      // dispatch(getMenuLoader(false));
      dispatch({
        type: FETCH_MENU_FAILURE
      });
    }
  } catch (e) {
    alert(e.message);
    // dispatch(getMenuLoader(false));
    dispatch({
      type: FETCH_MENU_FAILURE
    });
  }
};
export const getAllCacheMenus = (id) => async (dispatch) => {
  // dispatch(getMenuLoader(true));
  dispatch({
    type: FETCH_MENU_REQUEST
  });
  try {
    let { data } = await menuRepository.getAllCacheMenus(id);
    if (data.success) {
      dispatch({
        type: FETCH_MENU_SUCCESS,
        payload: data.data
      });
      // dispatch(getMenuLoader(false));
    } else {
      // dispatch(getMenuLoader(false));
      alert('Failed to fetch Cache Menus');
      dispatch({
        type: FETCH_MENU_FAILURE
      });
    }
  } catch (e) {
    alert(e.message);
    dispatch({
      type: FETCH_MENU_FAILURE
    });
    // dispatch(getMenuLoader(false));
  }
};
export const getAllTakeAwayMenus = (id) => async (dispatch) => {
  // dispatch(getMenuLoader(true));
  dispatch({
    type: FETCH_TAKEAWAY_MENU_REQUEST
  });
  try {
    let { data } = await menuRepository.getAllTakeAwayMenus(id);
    if (data.success) {
      dispatch({
        type: FETCH_TAKEAWAY_MENU_SUCCESS,
        payload: data.data
      });
      // dispatch(getMenuLoader(false));
    } else {
      dispatch({
        type: FETCH_TAKEAWAY_MENU_FAILURE
      });
    }
  } catch (e) {
    alert(e.message);
    dispatch({
      type: FETCH_TAKEAWAY_MENU_FAILURE
    });
    // dispatch(getMenuLoader(false));
  }
};
export const getAllKioskMenu = (id) => async (dispatch) => {
  // dispatch(getMenuLoader(true));
  dispatch({
    type: FETCH_KIOSK_MENU_REQUEST
  });
  try {
    let { data } = await menuRepository.getAllKioskMenu(id);
    if (data.success) {
      dispatch({
        type: FETCH_KIOSK_MENU_SUCCESS,
        payload: data.data
      });
      // dispatch(getMenuLoader(false));
    } else {
      // dispatch(getMenuLoader(false));
      dispatch({
        type: FETCH_KIOSK_MENU_FAILURE
      });
    }
  } catch (e) {
    alert(e.message);
    dispatch({
      type: FETCH_KIOSK_MENU_FAILURE
    });
    // dispatch(getMenuLoader(false));
  }
};
export const getSingleMenuItems = (payload) => async (dispatch) => {
  // dispatch(getMenuLoader(true));
  dispatch({
    type: FETCH_SINGLE_MENU_ITEMS_REQUEST
  });
  try {
    let { data } = await menuRepository.getSingleMenuItems(payload);
    if (data?.success) {
      dispatch({
        type: FETCH_SINGLE_MENU_ITEMS_SUCCESS,
        payload: data?.data
      });
      // dispatch(getMenuLoader(false));
    } else {
      dispatch({
        type: FETCH_SINGLE_MENU_ITEMS_FAILURE
      });
      // dispatch(getMenuLoader(false));
    }
  } catch (e) {
    dispatch({
      type: FETCH_SINGLE_MENU_ITEMS_FAILURE
    });
    // dispatch(getMenuLoader(false));
  }
};
export const createMenuItem = (payload) => async (dispatch) => {
  // dispatch(menuLoader(true));
  dispatch({
    type: CREATE_MENU_REQUEST
  });
  try {
    let { data } = await menuRepository.createMenuItem(payload);
    if (data.success) {
      alert('Menu Saved');
      dispatch(getSingleMenuItems(payload.menu_id));
      // dispatch(menuLoader(false));
      dispatch({
        type: CREATE_MENU_SUCCESS
      });
    } else {
      // dispatch(menuLoader(false));
      alert('Failed to create menu item, please try again');
      dispatch({
        type: CREATE_MENU_FAILURE
      });
    }
  } catch (e) {
    alert(e.message);
    // dispatch(menuLoader(false));
    dispatch({
      type: CREATE_MENU_FAILURE
    });
  }
};
export const deleteMenu = (payload, blId) => async (dispatch) => {
  // dispatch(menuLoader(true));
  dispatch({
    type: DELETE_MENU_REQUEST
  });
  try {
    let { data } = await menuRepository.deleteMenu(payload);
    if (data?.success) {
      alert('Menu Removed');
      dispatch(getAllMenus(blId));
      dispatch(getAllTakeAwayMenus(blId));
      dispatch(getAllKioskMenu(blId));
      // dispatch(menuLoader(false));
      dispatch({
        type: DELETE_MENU_SUCCESS
      });
    } else {
      // dispatch(menuLoader(false));
      alert('Something went wrong trying to delete Menu');
      dispatch({
        type: DELETE_MENU_FAILURE
      });
    }
  } catch (e) {
    alert(e.message);
    // dispatch(menuLoader(false));
    dispatch({
      type: DELETE_MENU_FAILURE
    });
  }
};
const sortItem = (menus, obj) => {
  let { startIndex, endIndex } = obj;

  let data = menus
    .map((menu) => {
      if (startIndex < endIndex) {
        if (menu.position === startIndex) {
          menu.position = endIndex;
        } else if (menu?.position > startIndex && menu?.position <= endIndex) {
          menu.position = menu.position - 1;
        }
      } else if (startIndex > endIndex) {
        if (menu?.position === startIndex) {
          menu.position = endIndex;
        } else if (menu?.position < startIndex && menu?.position >= endIndex) {
          menu.position = menu.position + 1;
        }
      }
      // menu.group = menu.group + "-" + menu.position;

      return menu;
    })
    .sort((a, b) => {
      if (!a.position || !b.position) {
        return -1;
      }
      return a.position - b.position;
    });
  return data;
};
export const positionDragItem = (payload, blId, menus) => async (dispatch) => {
  // dispatch(setMenuLoader(true));
  dispatch({
    type: SET_MENU_POSITION_REQUEST
  });
  const sortedData = sortItem(menus, payload);

  try {
    dispatch(setMenus({ success: true, data: sortedData }));
    let { data } = await menuRepository.positionDragItem(payload, blId);
    if (data?.success) {
      // dispatch(setMenuLoader(false));
      dispatch({
        type: SET_MENU_POSITION_SUCCESS
      });
      // TODO: why we need to refetch all menus again??
      dispatch(getAllMenus(blId));
      dispatch(getAllKioskMenu(blId));
      dispatch(getAllTakeAwayMenus(blId));
    }
  } catch (e) {
    // dispatch(setMenuLoader(false));
    dispatch({
      type: SET_MENU_POSITION_FAILURE
    });
    console.log(e.message);
  }
};
export const addPriority = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PRIORITY_REQUEST
    });
    let { data } = await menuRepository.addPriority(payload);
    dispatch({
      type: SET_PRIORITY_SUCCESS,
      payload: data
    });
    return data;
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: SET_PRIORITY_FAILURE
    });
    return err;
  }
};
export const updateposition=(blId)=>async (dispatch)=>{
  dispatch({
    type: UPDATE_MENU_POSITION_REQUEST
  });
  try {
    // dispatch(setMenus({ success: true, data: sortedData }));
    let { data } = await menuRepository.updatePosition(blId);
    //let { data } = await axios.put(`${process.env.REACT_APP_BackendURL}/menu_v2/updateposition/${blId}`);
    if (data?.success) {
     //console.log("_UPDATE_MENU_POSITION_FAILURE__",data);
      dispatch({
        type: UPDATE_MENU_POSITION_SUCCESS
      });
      // TODO: why we need to refetch all menus again??
      dispatch(getAllMenus(blId));
    }
  } catch (e) {

    dispatch({
      type: UPDATE_MENU_POSITION_FAILURE
    });
    console.log(e.message);
  }
};


